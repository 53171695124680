.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Loader spinner */
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3f4d67;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ee8514;
  -webkit-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

html,
body {
  background: #f4f7fa;
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding: 0px 0 10px 0;
}

h1 i {
  color: #ee8514;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
}

h3{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding: 0px 0 10px 0;
}

.sidebar {
  background: #3f4d67;
  width: 265px;
  height: 100vh;
  position: fixed;
}

.sidebar ul {
  padding: 0;
}

.sidebar ul li {
  list-style-type: none;
  font-size: 13px;
  font-weight: 400;
  padding: 15px 0;
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar ul li a {
  color: #a9b7d0;
  text-decoration: none;
}

.sidebar ul li:has(> a.active) {
  background: #333f54;
  border-left: solid 3px #ee8514;
}

.sidebar ul li a.active {
  color: #ffffff;
}

.sidebar ul li a.active i {
  color: #ee8514;
}

.sidebar i {
  color: #a9b7d0;
  font-size: 13px;
  width: 10px;
  display: inline-block;
  text-align: center;
  line-height: 1;
  margin-right: 1.125rem;
}

.sidebar .user {
  color: #ffffff;
  border-radius: 100%;
  text-align: center;
  background: #a6c6f5;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  margin-bottom: 15px;
}

.content {
  padding: 45px 45px 0px 310px;
}

.search-bar input {
  border: 1px solid #ced4da !important;
  padding: 7px 15px !important;
  margin-right: 5px !important;
  background: #ffffff;
  border-radius: 7px;
  font-size: 13px;
  width: 400px;
}

.searchbar {
  background: #ffffff;
  width: 100%;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  padding: 20px 25px 20px 75px;
  margin-bottom: 50px;
}

#DataTable {
  background: #ffffff;
  /* box-shadow: 1px 1px 5px rgb(0 0 0 / 10%); */
  box-shadow: 0 2px 0px 1px rgb(0 0 0 / 3%);
}

.dataTables_length {
  font-size: 14px;
  display: inline-block;
}

.dataTables_filter {
  display: inline-block;
  background: #ffffff;
  padding: 9px 20px !important;
  font-size: 14px;
  box-shadow: 0 2px 0px 1px rgb(0 0 0 / 3%);
}

.form-block {
  background: #ffffff;
  box-shadow: 0 2px 0px 1px rgb(0 0 0 / 3%);
  padding: 30px;
  margin-bottom: 20px;
  font-size: 14px;
}

.form-block input {
  font-size: 14px;
}

.groups .btn {
  background: #ee8514;
  color: #fff;
  border: none;
  width: auto;
  display: block;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 10px 0;
}

/* form button {
  float: right;
  margin-top: 40px;
} */

.btn-right-top {
  float: right;
}

.btn-primary {
  background: #1f8014;
  color: #ffffff;
  font-size: 13px;
  border: none;
  height: 35px;
  padding: 0 20px;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 25%);
}

.btn-primary:hover {
  background: #1f8014;
}

.btn-delete {
  background: #d80000;
  color: #ffffff;
  font-size: 13px;
  height: 35px;
  padding: 0 20px;
  padding: 0 20px;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 25%);

}

.btn-delete:hover {
  background: #d80000;
  color: #ffffff;
}

.modal {
  margin-left: 265px;
  background: #f4f7fa;
  width: 85%;
}

.modal-dialog {
  background: #f4f7fa;
  margin-left: 45px;
  margin-top: 45px;
  width: 100%;
  max-width: 96%;
}

.modal-header {
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 20px;
}

.modal-body {
  padding: 0;
}

.modal-content {
  background: transparent;
  border: none;
}

.sidebar .logout {
  position: absolute;
  bottom: 0;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  width: 100%;
}

/* Login */

.login-wrapper {
  background: #3f4d67;
  width: 100%;
  height: 100vh;
  position: fixed;
}

.login, .loginPassword {
  background: #ffffff;
  width: 390px;
  padding: 30px;
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  text-align: center;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
}

.login h2 {
  font-size: 26px;
  width: 100%;
  text-align: center;
  padding: 15px 25px 25px 25px;
  font-weight: 300;
}

.login i {
  font-size: 35px;
  color: #ee8514;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 25%)
}

.login form label {
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.login form input {
  font-size: 14px;
}

.login form button, .loginPassword form button{
  background: #ee8514;
  margin-top:15px;
}

.login form button:hover, .loginPassword form button:hover{
  background: #ee8514;
}

.loginPassword{
  display: none;
}

#err{
  display: none;
  font-size: 13px;
  background: rgba(255,0,0, 0.3);
  border: solid 2px rgba(255,0,0,0.5);
  padding: 5px;
}

.alert-success, .alert-danger{
  display:none;
  font-size:14px;
}